<template>
  <div>
    <custHomePage v-if="systemPlatformRoles==='03'" />
    <agentHomePage v-else />
  </div>
</template>

<script>
import agentHomePage from '@/views/agentPage/homePage/index'
import custHomePage from '@/views/custPage/homePage/index'
export default {
  components: {
    agentHomePage,
    custHomePage
  },
  data() {
    return {
      systemPlatformRoles: '03'
    }
  },
  mounted() {
    this.systemPlatformRoles = sessionStorage.getItem('systemPlatformRoles') || this.$store.state.systemPlatformRoles
  }
}
</script>
