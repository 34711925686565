<template>
  <div class="homePage">
    <!-- 我的授信 -->
    <div class="creditBox">
      <div class="left">
        <div class="title">
          <span>我的授信</span>
          <router-link :to="{path:'/quotaManage/index'}">
            查看全部
          </router-link>
        </div>
        <!-- 可用额度 -->
        <div class="availableCredit">
          <div v-for="(item,index) in informationStatistics.creditInfoRespList||[]" :key="index" :class="['items',index%3===1?'items1':'',index%3===2?'items2':'']">
            <img v-if="index%3===0" class="icon" src="@/assets/icon/icon7.png" alt="icon">
            <img v-else-if="index%3===1" class="icon" src="@/assets/icon/icon8.png" alt="icon">
            <img v-else-if="index%3===2" class="icon" src="@/assets/icon/icon6.png" alt="icon">
            <h2 class="elp-1">
              {{ item.fundsCmpName }}
            </h2>
            <p>可用额度：</p>
            <h3>￥{{ item.creditAvlbAmt }}</h3>
            <span>{{ item.validDate }}&nbsp;&nbsp;&nbsp;到期</span>
          </div>
          <div v-if="!informationStatistics.creditInfoRespList||(informationStatistics.creditInfoRespList&&informationStatistics.creditInfoRespList.length===0)" class="noCreditBox">
            <img src="@/assets/image/noCredit.png" alt="图片">
            <span>暂无授信~</span>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- 公司名称 -->
        <div class="corporateInformation">
          <span class="abbreviation">
            {{ userInfo.abbreviation }}
          </span>
          <div class="name">
            <h3 class="elp-1">
              {{ userInfo.orgName||'' }}
            </h3>
            <p>完成认证后可立享合同签署、电子签章等权益</p>
          </div>
          <p class="button">
            <el-button v-if="userInfo.authState==='0'" type="primary" @click="enterpriseCertification">
              立即认证
            </el-button>
            <img v-else src="@/assets/image/yirenzheng2.png" alt="已认证">
          </p>
        </div>
        <!-- 人数角色统计 -->
        <div class="peopleCounting">
          <div class="list">
            <p>组织总人数</p>
            <span>{{ statisticsObj.userCount }}</span>
          </div>
          <div class="list">
            <p>超级管理员</p>
            <span>{{ statisticsObj.adminUserName }}</span>
          </div>
          <div class="list">
            <p>创建的角色数</p>
            <span>{{ statisticsObj.roleCount }}</span>
          </div>
        </div>
        <div class="buttonAssembly">
          <a href="#" @click="skip('/institutionalFramework/user')">
            <img src="@/assets/image/homePage_1.png" alt="icon">
            添加成员
          </a>
          <a href="#" @click="skip('/institutionalFramework/role')">
            <img src="@/assets/image/homePage_2.png" alt="icon">
            新增角色
          </a>
        </div>
      </div>
    </div>
    <!-- 我的待办 -->
    <div class="backlogBox">
      <h3 class="title">
        我的待办
      </h3>
      <div class="backlog">
        <div class="matter">
          <div class="list">
            <h4>授信申请</h4>
            <img class="backlogImg" src="@/assets/icon/backlog1.png" alt="icon">
            <router-link
              :to="{path:'/quotaManage/index', query:{creditStatus:'4'}}"
            >
              {{ informationStatistics.creditApplySignNum || 0 }}<i>笔</i>
            </router-link>
            <span>待签署授信协议</span>
          </div>
          <div class="list">
            <h4>采购计划</h4>
            <img class="backlogImg" src="@/assets/icon/backlog2.png" alt="icon">
            <router-link :to="{path:'/purchasePlanManage/index',query:{planStatus:'1'}}">
              {{ informationStatistics.planToDepositNum || 0 }}<i>笔</i>
            </router-link>
            <span>待支付保证金</span>
          </div>
        </div>
        <div class="matter">
          <div class="list">
            <h4>采购合同</h4>
            <img class="backlogImg" src="@/assets/icon/backlog3.png" alt="icon">
            <router-link :to="{path:'/purchaseContractManage/index',query:{contractStatus:'1'}}">
              {{ informationStatistics.contractToSignNum || 0 }}<i>笔</i>
            </router-link>
            <span>待签署合同</span>
          </div>
          <div class="list">
            <img class="backlogImg" src="@/assets/icon/backlog4.png" alt="icon">
            <router-link :to="{path:'/purchaseContractManage/index',query:{contractStatus:'6'}}">
              {{ informationStatistics.contractToDeliveryNum || 0 }}<i>笔</i>
            </router-link>
            <span>待提货</span>
          </div>
        </div>
        <div class="matter">
          <div class="list">
            <h4>合同结算</h4>
            <img class="backlogImg" src="@/assets/icon/backlog5.png" alt="icon">
            <router-link :to="{path:'/financialManage/custSettlementManage',query:{settleStatus:'2'}}">
              {{ informationStatistics.settleDiffToPaid || 0 }}<i>笔</i>
            </router-link>
            <span>待支付结算差额</span>
          </div>
          <div class="list">
            <img class="backlogImg" src="@/assets/icon/backlog6.png" alt="icon">
            <router-link :to="{path:'/financialManage/custSettlementManage',query:{settleStatus:'4'}}">
              {{ informationStatistics.settleToInvoiced || 0 }}<i>笔</i>
            </router-link>
            <span>待开票</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 统计概览 -->
    <div class="statisticalOverview">
      <h3 class="title">
        统计概览
      </h3>
      <div class="statistical">
        <div class="list">
          <h3><img src="@/assets/icon/statistical7.png" alt="icon">采购计划</h3>
          <p><img src="@/assets/icon/statistical5.png" alt="icon">数量：{{ informationStatistics.totalPlannedWeight || 0 }}吨</p>
          <p><img src="@/assets/icon/statistical6.png" alt="icon">金额：{{ thousandsFractionsSmaller(informationStatistics.totalPlannedAmount || 0) }}元</p>
        </div>
        <div class="list">
          <h3><img src="@/assets/icon/statistical1.png" alt="icon">采购合同</h3>
          <p><img src="@/assets/icon/statistical5.png" alt="icon">数量：{{ informationStatistics.totalContractedWeight || 0 }}吨</p>
          <p><img src="@/assets/icon/statistical6.png" alt="icon">金额：{{ thousandsFractionsSmaller(informationStatistics.totalContractedAmount || 0) }}元</p>
        </div>
        <div class="list">
          <h3><img src="@/assets/icon/statistical2.png" alt="icon">累计提货</h3>
          <p><img src="@/assets/icon/statistical5.png" alt="icon">数量：{{ informationStatistics.totalDeliveryWeight || 0 }}吨</p>
        </div>
        <div class="list">
          <h3><img src="@/assets/icon/statistical3.png" alt="icon">累计支付(限代采)</h3>
          <p><img src="@/assets/icon/statistical6.png" alt="icon">金额：{{ thousandsFractionsSmaller(informationStatistics.totalPaidAmount || 0) }}元</p>
        </div>
        <div class="list">
          <h3><img src="@/assets/icon/statistical4.png" alt="icon">累计结算</h3>
          <p><img src="@/assets/icon/statistical5.png" alt="icon">数量：{{ informationStatistics.totalSettleWeight || 0 }}吨</p>
          <p><img src="@/assets/icon/statistical6.png" alt="icon">金额：{{ thousandsFractionsSmaller(informationStatistics.totalSettleAmount || 0) }}元</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { thousandsFractionsSmaller } from '@/unit/index'
import { cmpBaseinfoAuth } from '@/http/custApi/enterpriseInformation'
import { getUserAdminRoleCount } from '@/http/custApi/login'
import { agentPurchaseContractHomeSummary } from '@/http/custApi/common'
export default {
  data() {
    return {
      userInfo: {},
      statisticsObj: {},
      informationStatistics: {}
    }
  },
  mounted() {
    this.userInfo = { ...JSON.parse(localStorage.getItem('userInfoCust')) }
    getUserAdminRoleCount({ cmpRole: '03' }, res => {
      this.statisticsObj = { ...res.data }
    })
    // 信息统计
    agentPurchaseContractHomeSummary(res => {
      if (res.data && res.data.custHomeSummary && res.data.custHomeSummary.creditInfoRespList) {
        res.data.custHomeSummary.creditInfoRespList.forEach(item => {
          item.creditAvlbAmt = thousandsFractionsSmaller(Number(item.creditAvlbAmt), 2)
          item.validDate = item.validDate.replace(/-/g, '/')
        })
      }
      if (res.data && res.data.custHomeSummary) this.informationStatistics = { ...res.data.custHomeSummary }
    })
    if (this.userInfo.orgName) { this.userInfo.abbreviation = this.userInfo.orgName.slice(0, 2) }
  },
  methods: {
    thousandsFractionsSmaller,
    // 点击天机成员时的跳转
    skip(url) {
      // 判断所拥有的权限   是否能跳过去
      const allPermissionList = this.$store.state.allPermissionList || []
      const flag = allPermissionList.length === 0 ? false : allPermissionList.every(item => item.resUrl !== url)
      if (flag) {
        this.$message({
          message: '当前页面暂无权限',
          type: 'error'
        })
        return
      }
      this.$router.push({ path: url })
    },
    // 企业认证
    enterpriseCertification() {
      cmpBaseinfoAuth({ cmpCode: JSON.parse(localStorage.getItem('userInfoCust')).cmpId }, res => {
        window.open(res.data)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.homePage {
  .statisticalOverview{
    border-radius: 4px;
    padding: 24px;
    background-color: #fff;
    .statistical{
      display: flex;
      .list{
        width: calc(20% - 13px);
        height: 144px;
        margin-right: 16px;
        background-color: #F9F9F9;
        p{
          display: flex;
          align-items: center;
          padding: 0 24px;
          color: #666666;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 6px;
          img{
            height: 24px;
            width: 24px;
            margin-right: 8px;
          }
        }
        h3{
          display: flex;
          align-items: center;
          padding: 0 24px;
          color: #000000;
          font-size: 16px;
          font-weight: 500;
          margin: 24px 0 16px;
          img{
            height: 24px;
            width: 24px;
            margin-right: 8px;
          }
        }
      }
      .list:last-of-type{
        margin-right: 0;
      }
    }
    .title{
      color: #000;
      font-size: 18px;
      font-weight: 400;
      padding-bottom: 24px;
    }
  }
  .backlogBox{
    margin: 18px 0 16px;
    background-color: #fff;
    border-radius: 4px;
    .backlog{
      display: flex;
      padding: 0 24px 24px;
      .matter{
        display: flex;
        justify-content: space-between;
        width: calc(33.33% - 11px);
        margin-left: 16px;
        background-color: #F9F9F9;
        .list{
          display: flex;
          align-items: center;
          flex-direction: column;
          position: relative;
          width: calc(50% - 8px);
          height: 292px;
          margin-right: 16px;
          span{
            font-size: 14px;
              color: #666;
              font-weight: 400;
          }
          a{
            color: #000000;
            font-size: 28px;
            font-weight: 500;
            padding: 32px 0 10px;
            i{
              font-style: normal;
              font-size: 12px;
              color: #666;
              font-weight: 400;
            }
          }
          a:hover{
            color: $mainBlue;
            i{
              color: $mainBlue;
            }
          }
          .backlogImg{
            display: block;
            height: 64px;
            width: 64px;
            margin-top: 102px;
          }
          h4{
            position: absolute;
            top: 24px;
            left: 24px;
            color: #333;
            font-size: 16px;
            text-align: left;
          }
        }
        .list:last-of-type{
          margin-right: 0;
        }
      }
      .matter:first-of-type{
        margin-left: 0;
        background-color: #fff;
        .list{
          background-color: #F9F9F9;
        }
      }
    }
    .title{
      padding: 24px;
      color: #000000;
      font-size: 18px;
      font-weight: 500;
    }
  }
  .creditBox{
    display: flex;
    height: 258px;
    .right{
      width: 33%;
      height: 100%;
      margin-left: 16px;
      background-color: #fff;
      margin-bottom: 24px;
      border-radius: 4px;
      .buttonAssembly {
        display: flex;
        padding: 6px 24px;
        a {
          display: flex;
          height: 44px;
          width: calc(50% - 8px);
          align-items: center;
          justify-content: center;
          margin-right: 16px;
          background-color: #f9f9f9;
          font-size: 14px;
          color: #000;
          img {
            height: 24px;
            width: 24px;
            margin-right: 8px;
          }
        }
        a:last-of-type{
          margin-right: 0;
        }
      }
      .peopleCounting{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 24px 22px;
        .list{
          flex: 1;
          color: #666666;
          font-size: 14px;
          span{
            color: #000000;
            font-size: 24px;
            font-weight: 500;
          }
        }
      }
      .corporateInformation{
        display: flex;
        align-items: center;
        padding: 24px;
        .name{
          flex: 1;
          margin-left: 12px;
          h3{
            color: #000;
            font-size: 18px;
            font-weight: 500;
          }
          p{
            color: #666666;
            font-size: 14px;
            margin-top: 4px;
          }
        }
        .button{
          flex: 0 0 88px;
          text-align: right;
          img{
            height: 56px;
            width: 56px;
          }
          .el-button{
            width: 88px;
            height: 32px;
            // line-height: 32px;
            padding: 0;
          }
        }
        .abbreviation{
          display: inline-block;
          height: 56px;
          flex: 0 0 56px;
          text-align: center;
          line-height: 56px;
          border-radius: 50%;
          color: #fff;
          background-color: #007EFF;
          font-size: 20px;
        }
      }
    }
    .left{
      width: calc(67% - 16px);
      background-color: #fff;
      height: 100%;
      border-radius: 4px;
      .availableCredit{
        padding: 0 24px 4px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        overflow-y: hidden;
        overflow-x: auto;
        .noCreditBox{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          color: #666;
          font-size: 14px;
          img{
            width: 164px;
            height: 132px;
            margin-bottom: 12px;
          }
        }
        .items{
          position: relative;
          flex: 0 0 calc(33.33% - 11px);
          height: 164px;
          margin-right: 16px;
          background-color: #F7F8FF;
          h2{
            color: #666666;
            font-size: 16px;
            font-weight: 400;
            margin: 24px 72px 18px 24px;
            width: calc(100% - 96px);
          }
          span{
            color: #666666;
            font-size: 14px;
            font-weight: 400;
            padding: 0 16px 0 24px;
          }
          h3{
            color: #000000;
            font-size: 28px;
            font-weight: 500;
            padding: 0 16px 0 24px;
            margin: 0 0 8px;
            transform: translateX(-6px);
          }
          p{
            color: #666666;
            font-size: 16px;
            font-weight: 400;
            padding: 0 16px 0 24px;
          }
          .icon{
            position: absolute;
            top: 24px;
            right: 16px;
            height: 56px;
            width: 56px;
          }
        }
        // .items:hover{
        //   box-shadow:  0 0 10px rgba(0,0,0,.05);
        // }
        .items1 {
          background-color: #F5FCF3;
        }
        .items2 {
          background-color: #F0F7FF;
        }
        .items:last-of-type{
          margin-right: 0;
        }
      }
      .title{
        padding: 24px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #000;
        font-size: 18px;
        font-weight: 500;
        a{
          color: #007EFF;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
</style>
